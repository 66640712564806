import React, { FC, useCallback, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CarouselNavigation from 'components/elements/Carousel/CarouselNavigation';

import { IPropsCarousel } from './models';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import './Carousel.scss';

SwiperCore.use([Navigation, Pagination]);

const Carousel: FC<IPropsCarousel> = ({
  children,
  slidesPerView,
  ariaLabelPrev,
  ariaLabelNext,
  isDesktop,
  dir = 'ltr',
  isShowPagination = true,
  isShowNavigation = true,
  loop = false,
}) => {
  const navPrevRef = useRef<HTMLButtonElement>(null);
  const navNextRef = useRef<HTMLButtonElement>(null);

  const [init, setInit] = useState(false);

  const isInitHandler = useCallback((): void => {
    setInit(!init);
  }, [init]);

  return (
    <div data-testid="Carousel" className="carousel">
      <Swiper
        key={isShowPagination ? '0' : '1'}
        className="carousel-swiper"
        {...(isShowPagination && {
          pagination: {
            clickable: true,
          },
        })}
        slidesPerView={slidesPerView}
        spaceBetween={isDesktop ? 60 : 13}
        wrapperTag="ul"
        navigation={{
          prevEl: navPrevRef.current,
          nextEl: navNextRef.current,
        }}
        dir={dir}
        loop={loop}
        onSwiper={isInitHandler}
        loopedSlides={1}
      >
        {children?.length >= 1 &&
          children.map((el, index) => (
            <SwiperSlide
              key={`carousel-${index.toString()}`}
              className="carousel-swiper__slide"
              tag="li"
            >
              {el}
            </SwiperSlide>
          ))}
      </Swiper>
      {isShowNavigation ? (
        <CarouselNavigation
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          navPrevRef={navPrevRef}
          navNextRef={navNextRef}
        />
      ) : null}
    </div>
  );
};

export default Carousel;
