import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageIntro from 'components/elements/PageIntro';
import ContactUs from 'components/sections/ContactUs';
import ProductBaseInfo from 'components/sections/ProductBaseInfo';
import SafetyOverview from 'components/sections/SafetyOverview';

import { IPropsProductPage } from './models';

import './ProductPage.scss';

const ProductPage: FC<IPropsProductPage> = ({
  data: { pageData, pdpSettings, siteSettings },
  pageContext,
}) => {
  const { urls, seo, title } = pageData;
  const { breadcrumbs, lang } = pageContext;

  return (
    <Layout data-testid="ProductPage" seo={seo} lang={lang} urls={urls} className="product-page">
      <PageIntro title={title} breadcrumbs={breadcrumbs} />

      <SafetyOverview
        safetyOverview={pageData?.safetyOverview}
        siteSettings={siteSettings}
        pdpSettings={pdpSettings}
      />

      <ProductBaseInfo productBaseInfo={pageData?.productBaseInfo} />

      <ContactUs
        contactUs={pageData?.contactUs?.[0] ? pageData.contactUs : pdpSettings?.contactUs}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!) {
    pageData: productPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentProductPage
    }
    pdpSettings: pdpSettingsType(lang: { eq: $lang }) {
      ...FragmentPdpSettings
    }
    siteSettings: siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
  }
`;

export default ProductPage;
