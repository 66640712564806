import React, { FC, useCallback, useEffect, useState } from 'react';

import Accordion from 'components/elements/Accordion';
import ExternalLink from 'components/elements/ExternalLink';
import InfoCard from 'components/elements/InfoCard';

import { IPropsProductBaseInfo } from './models';

import './ProductBaseInfo.scss';

const ProductBaseInfo: FC<IPropsProductBaseInfo> = ({ productBaseInfo }) => {
  const [accordionToIndexMap, setAccordionToIndexMap] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    if (!productBaseInfo?.[0]?.accordions?.length) {
      return;
    }

    const stateIndexesMap = {};

    productBaseInfo[0].accordions.forEach((accordion) => {
      stateIndexesMap[accordion.title] = accordion.items.findIndex((item) => item.isOpened === '1');
    });

    setAccordionToIndexMap(stateIndexesMap);
  }, []);

  const onChangeCallback = useCallback((selectedIndex: number, accordionTitle: string) => {
    setAccordionToIndexMap((prevState) => ({
      ...prevState,
      [accordionTitle]: prevState[accordionTitle] === selectedIndex ? -1 : selectedIndex,
    }));
  }, []);

  return productBaseInfo?.[0] ? (
    <InfoCard data-testid="ProductBaseInfo" title={productBaseInfo[0].title}>
      {productBaseInfo[0]?.accordions?.length ? (
        <div className="product-base-info">
          {productBaseInfo[0].accordions.map((item) => (
            <Accordion
              key={item.title}
              accordion={item}
              selectedIndex={accordionToIndexMap[item.title]}
              onChange={onChangeCallback}
              changeOnClick={false}
              scrollTabIntoView
            />
          ))}
        </div>
      ) : null}

      {productBaseInfo[0]?.leaflet?.[0]?.pdf?.gatsbyDocFile?.publicURL ? (
        <ExternalLink
          link={productBaseInfo[0].leaflet[0].pdf.gatsbyDocFile.publicURL}
          title={productBaseInfo[0].leaflet[0].title}
          target="__blanc"
        />
      ) : null}
    </InfoCard>
  ) : null;
};

export default ProductBaseInfo;
