import React, { FC, useCallback, useState } from 'react';

import InfoCard from 'components/elements/InfoCard';
import VideoCarousel from 'components/elements/VideoCarousel';
import VideoCMS from 'components/elements/VideoCMS';
import VideoTranscription from 'components/elements/VideoTranscription';

import { IPropsSafetyOverview, IVideoRange } from './models';

import './SafetyOverview.scss';

const SafetyOverview: FC<IPropsSafetyOverview> = ({
  safetyOverview,
  siteSettings,
  pdpSettings,
}) => {
  const [videoRange, setVideoRange] = useState<IVideoRange>({
    start: 0,
    end: 0,
  });
  const [isVideoPlay, setIsVideoPlay] = useState<boolean>(false);
  const videoRef = React.useRef<null | HTMLVideoElement>(null);

  const togglePlay = useCallback(
    (time: number) => () => {
      if (!videoRef?.current) {
        return;
      }
      if (!isVideoPlay) {
        setIsVideoPlay(true);
      }
      videoRef.current.currentTime = time;
      videoRef.current.play();
    },
    [videoRef]
  );

  const setVideoRanges = useCallback((): IVideoRange | null => {
    if (!videoRef?.current?.seekable) {
      return null;
    }

    if (videoRange.end) {
      return videoRange;
    }

    const timeRangesObject = videoRef.current.seekable;
    const timeRanges: number[][] = [];
    for (let count = 0; count < timeRangesObject.length; count += 1) {
      timeRanges.push([timeRangesObject.start(count), timeRangesObject.end(count)]);
    }

    const start = timeRanges[0][0];
    const end = timeRanges[timeRanges.length - 1][1];

    setVideoRange({ start, end });

    return { start, end };
  }, [videoRef, videoRange]);

  const checkVideoRanges = useCallback((time: number, range: IVideoRange | null): boolean => {
    if (!range || time < range.start || time > range.end) {
      return false;
    }

    return true;
  }, []);

  const handleVideoTime = useCallback(
    (time: number) => () => {
      const result = setVideoRanges();

      if (!checkVideoRanges(time, result)) {
        return;
      }
      togglePlay(time)();
    },
    [videoRef, videoRange, isVideoPlay]
  );

  return safetyOverview?.[0]?.videoCms?.[0]?.video?.gatsbyVideoFile?.publicURL ? (
    <InfoCard title={safetyOverview[0].title} className="safety-overview-wrapper">
      <div data-testid="SafetyOverview" className="safety-overview">
        <VideoCMS
          videoCms={safetyOverview[0].videoCms}
          videoRef={videoRef}
          isVideoPlay={isVideoPlay}
          togglePlay={togglePlay}
        />

        <VideoTranscription
          videoTranscriptOpenState={pdpSettings.videoTranscriptOpenState}
          videoTranscriptCloseState={pdpSettings.videoTranscriptCloseState}
          items={safetyOverview[0].videoCms[0].items}
          videoRef={videoRef}
        />

        <VideoCarousel
          items={safetyOverview[0].videoCms[0].items}
          onClickHandler={handleVideoTime}
          ariaLabelPrev={siteSettings.ariaLabelPrev}
          ariaLabelNext={siteSettings.ariaLabelNext}
          dir={siteSettings.dir[0]}
        />
      </div>
    </InfoCard>
  ) : null;
};

export default SafetyOverview;
