import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import TinyAccordion from 'react-tiny-accordion';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import IconCustom from 'components/helpers/IconCustom';

import { scrollIntoViewAccordion } from './helpers';
import { IAccordionItem, IPropsAccordion } from './models';

import './Accordion.scss';

const OpenTabClassName = 'is-open';
const OpenAccordionClassName = 'open';
const AccordionParentClassName = 'accordion-parent';

const Accordion: FC<IPropsAccordion> = (props) => {
  const {
    accordion: { items, title },
    selectedIndex,
    onChange,
    changeOnClick,
    scrollTabIntoView,
    indicatorClassName,
    panelHeaderClassName,
  } = props;
  const { isMobile } = useScreenRecognition();
  const [tabsHeaderRef, setTabsHeaderRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [tabsContentRef, setTabsContentRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [panels, setPanels] = useState<IAccordionItem[]>();

  const onChangeCallback = useCallback(
    (index: number, isOpen: boolean) => {
      if (scrollTabIntoView && isOpen) {
        scrollIntoViewAccordion({
          index,
          tabsHeaderRef,
          parentName: AccordionParentClassName,
          openName: OpenAccordionClassName,
          isMobile,
        });
      }

      if (onChange) {
        onChange(index, title);
      }
    },
    [tabsHeaderRef]
  );

  useEffect(() => {
    setTabsHeaderRef(items.map(() => createRef()));
    setTabsContentRef(items.map(() => createRef()));
  }, []);

  useEffect(() => {
    setPanels(items);
  }, [items.length]);

  return panels ? (
    <div data-testid="Accordion" className="accordion">
      {title ? <h3 className="accordion__title">{title}</h3> : null}
      <TinyAccordion
        openClassName={OpenAccordionClassName}
        selectedIndex={selectedIndex}
        transitionDuration={scrollTabIntoView && isMobile ? 0 : 200}
        onChange={onChangeCallback}
        changeOnClick={changeOnClick}
        className={AccordionParentClassName}
      >
        {panels.map((item: IAccordionItem, i) =>
          item.content && item.title ? (
            <div
              key={typeof item.title === 'string' ? item.title : item.id}
              data-testid="header"
              data-header={
                <div ref={tabsHeaderRef[i]}>
                  <button type="button" className="accordion__header">
                    <IconCustom
                      icon="arrow"
                      className={classNames('indicator', {}, indicatorClassName)}
                    />
                    <span
                      className={classNames('accordion__header-text', {}, panelHeaderClassName)}
                    >
                      {!item?.titleOpened ? item.title : null}
                      {item?.titleOpened && selectedIndex === i ? item.title : item.titleOpened}
                    </span>
                  </button>
                </div>
              }
            >
              {typeof item.content === 'string' ? (
                <DangerouslySetInnerHtml
                  refObj={tabsContentRef[i]}
                  className={classNames('accordion__content', {
                    [OpenTabClassName]: item.isOpened === '1',
                  })}
                  html={item.content}
                />
              ) : (
                <div
                  ref={tabsContentRef[i]}
                  className={classNames('accordion__content', {
                    [OpenTabClassName]: item.isOpened === '1',
                  })}
                >
                  {item.content}
                </div>
              )}
            </div>
          ) : null
        )}
      </TinyAccordion>
    </div>
  ) : null;
};

export default Accordion;
