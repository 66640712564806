import React, { FC } from 'react';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsCarouselNavigation } from './models';

import './CarouselNavigation.scss';

const CarouselNavigation: FC<IPropsCarouselNavigation> = ({
  ariaLabelNext,
  ariaLabelPrev,
  navPrevRef,
  navNextRef,
}) => (
  <div data-testid="CarouselNavigation" className="carousel-navigation">
    <button
      type="button"
      className="carousel-navigation__item"
      aria-label={ariaLabelPrev}
      ref={navPrevRef}
    >
      <IconCustom icon="arrow" className="icon-prev" />
    </button>
    <button
      type="button"
      className="carousel-navigation__item"
      aria-label={ariaLabelNext}
      ref={navNextRef}
    >
      <IconCustom icon="arrow" className="icon-next" />
    </button>
  </div>
);

export default CarouselNavigation;
