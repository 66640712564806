import { MOBILE_MIDDLE_BREAKPOINT, TABLET_BREAKPOINT } from 'utils/constants';

import {
  DefaultItemWidthDesktop,
  DefaultItemWidthMobile,
  DefaultItemWidthMobileMiddle,
  MaxCarouselWidth,
  MaxItems,
} from './constants';

const ITEM_WIDTH_MOBILE_BY_LOCALE = {
  'ta-in': 135 + 20,
};

const ITEM_WIDTH_MOBILE_MIDDLE_BY_LOCALE = {
  'ta-in': 140 + 30,
};

const getSlidesPerView = (windowWidth: number, currentLangCode?: string): number => {
  const currentItemWidthMobile =
    currentLangCode && ITEM_WIDTH_MOBILE_BY_LOCALE?.[currentLangCode]
      ? ITEM_WIDTH_MOBILE_BY_LOCALE?.[currentLangCode]
      : DefaultItemWidthMobile;

  const currentItemWidthMobileMiddle =
    currentLangCode && ITEM_WIDTH_MOBILE_MIDDLE_BY_LOCALE?.[currentLangCode]
      ? ITEM_WIDTH_MOBILE_MIDDLE_BY_LOCALE?.[currentLangCode]
      : DefaultItemWidthMobileMiddle;

  if (windowWidth > MaxCarouselWidth) {
    return MaxItems;
  }

  if (windowWidth < TABLET_BREAKPOINT && windowWidth > MOBILE_MIDDLE_BREAKPOINT) {
    return Math.floor(windowWidth / currentItemWidthMobile) + 0.1;
  }

  if (windowWidth < MOBILE_MIDDLE_BREAKPOINT) {
    return Math.floor(windowWidth / currentItemWidthMobileMiddle) + 0.4;
  }

  return Math.floor(windowWidth / DefaultItemWidthDesktop);
};

export default {
  getSlidesPerView,
};
