import React, { FC, useCallback, useState } from 'react';

import Accordion from 'components/elements/Accordion';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import IconCustom from 'components/helpers/IconCustom';

import { IPropsUpdatedInfo } from './models';

import './UpdatedInfo.scss';

const UpdatedInfo: FC<IPropsUpdatedInfo> = ({ updatedInfo: [data] }) => {
  const [accordionIndex, setAccordionIndex] = useState<number>(-1);

  const onChangeCallback = useCallback((selectedIndex: number) => {
    setAccordionIndex((prevState) => (selectedIndex === prevState ? -1 : selectedIndex));
  }, []);

  return (
    <div data-testid="UpdatedInfo" className="updated-info">
      <h5 className="updated-info__title">
        {data.title} <span className="updated-info__title-date">{data.titleDate}</span>
      </h5>

      <Accordion
        accordion={{
          title: null,
          items: [
            {
              title: (
                <>
                  <IconCustom icon="history" className="updated-info__accordion-title-icon" />
                  <span className="updated-info__accordion-title">{data.titleCloseState}</span>
                </>
              ),
              titleOpened: (
                <>
                  <IconCustom icon="history" className="updated-info__accordion-title-icon" />
                  <span className="updated-info__accordion-title">{data.titleOpenState}</span>
                </>
              ),
              id: 'updated-info',
              content: data.items?.length ? (
                <div className="updated-info__items">
                  {data.items.map((item) => (
                    <div key={item.title} className="updated-info__item">
                      <div className="updated-info__item-title">{item.title}</div>
                      <DangerouslySetInnerHtml
                        html={item.description}
                        className="updated-info__item-description"
                      />
                    </div>
                  ))}
                </div>
              ) : null,
              isOpened: '0',
            },
          ],
        }}
        scrollTabIntoView
        selectedIndex={accordionIndex}
        onChange={onChangeCallback}
        changeOnClick={false}
      />
    </div>
  );
};
export default UpdatedInfo;
