import React, { FC } from 'react';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsExternalLink } from './models';

import './ExternalLink.scss';

const ExternalLink: FC<IPropsExternalLink> = ({ title, link, target }) => (
  <div data-testid="ExternalLink" className="external-link__wrapper">
    <a href={link} className="external-link" target={target || undefined}>
      {title}
    </a>
    <IconCustom icon="external-link" />
  </div>
);
export default ExternalLink;
