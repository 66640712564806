import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import Accordion from 'components/elements/Accordion';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';

import { IPropsVideoTranscription } from './models';

import './VideoTranscription.scss';

const VideoTranscription: FC<IPropsVideoTranscription> = ({
  videoTranscriptCloseState,
  videoTranscriptOpenState,
  items,
  videoRef,
}) => {
  const [accordionIndex, setAccordionIndex] = useState<number>(-1);
  const [currentTranscript, setCurrentTranscript] = useState<string | undefined>(undefined);

  const onChangeCallback = useCallback(
    (selectedIndex: number) => {
      if (!currentTranscript) {
        return;
      }
      setAccordionIndex((prevState) => (selectedIndex === prevState ? -1 : selectedIndex));
    },
    [currentTranscript]
  );

  const trackVideoTime = useCallback(() => {
    if (!videoRef?.current) {
      return;
    }

    const seconds = Math.round(videoRef.current.currentTime);
    const transcript = items.find(
      (item) => seconds >= item.timeStart && seconds < item.timeEnd
    )?.text;

    setCurrentTranscript(transcript);
  }, []);

  useEffect(() => {
    videoRef?.current?.addEventListener('timeupdate', trackVideoTime);

    return () => {
      videoRef?.current?.removeEventListener('timeupdate', trackVideoTime);
    };
  }, []);

  return (
    <div data-testid="VideoTranscription" className="video-transcription">
      <Accordion
        key={currentTranscript}
        indicatorClassName={classNames('', {
          disabled: !currentTranscript,
        })}
        panelHeaderClassName={classNames('', {
          disabled: !currentTranscript,
        })}
        accordion={{
          title: null,
          items: [
            {
              title: videoTranscriptOpenState,
              titleOpened: videoTranscriptCloseState,
              id: 'video-transcription',
              content: (
                <DangerouslySetInnerHtml
                  html={currentTranscript}
                  className="video-transcription__description"
                />
              ),
              isOpened: '0',
            },
          ],
        }}
        scrollTabIntoView
        selectedIndex={accordionIndex}
        onChange={onChangeCallback}
        changeOnClick={false}
      />
    </div>
  );
};
export default VideoTranscription;
