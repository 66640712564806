import React, { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import GatsbyImage from 'components/helpers/GatsbyImage';
import IconCustom from 'components/helpers/IconCustom';

import { IPropsVideoCMS } from './models';

import './VideoCMS.scss';

const VideoCMS: FC<IPropsVideoCMS> = ({ videoCms: [data], videoRef, togglePlay, isVideoPlay }) => {
  const trackScrolling = useCallback(() => {
    if (videoRef?.current) {
      const { height, y } = videoRef.current.getBoundingClientRect();

      if (Math.abs(y) > height) {
        videoRef.current?.pause();
      }
    }
  }, [videoRef]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  });

  return videoRef ? (
    <div
      data-testid="VideoCMS"
      className={classNames('video-cms', {
        'video-cms--border': isVideoPlay,
      })}
    >
      <video ref={videoRef} controls className="video-cms__video">
        <source src={data.video.gatsbyVideoFile.publicURL} type="video/mp4" />
        <track default kind="captions" />
      </video>
      {!isVideoPlay ? (
        <>
          {data?.thumbnail ? (
            <GatsbyImage
              isLazyLoading={false}
              image={data.thumbnail}
              className="video-cms__thumbnail"
            />
          ) : null}
          <button
            aria-label={data.playButtonAriaLabel}
            className="video-cms__play-button icon-social-youtube"
            onClick={togglePlay(0)}
            type="button"
          >
            <IconCustom icon="play3" className="video-cms__play-icon" />
          </button>
        </>
      ) : null}
    </div>
  ) : null;
};
export default VideoCMS;
