import React, { FC, useEffect, useState } from 'react';
import { useCurrentLang } from 'hooks/useCurrentLang';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Carousel from 'components/elements/Carousel';
import Title from 'components/elements/Title';
import GatsbyImage from 'components/helpers/GatsbyImage';

import Helpers from './helpers';
import { IPropsVideoCarousel } from './models';

import './VideoCarousel.scss';

const VideoCarousel: FC<IPropsVideoCarousel> = ({
  items,
  onClickHandler,
  ariaLabelPrev,
  ariaLabelNext,
  dir,
}) => {
  const [slidesPerView, setSlidesPerView] = useState<number | null>(null);
  const { isDesktop, windowWidth } = useScreenRecognition();
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  useEffect(() => {
    if (!windowWidth) {
      return;
    }

    setSlidesPerView(Helpers.getSlidesPerView(windowWidth, currentLangCode));
  }, [windowWidth, currentLangCode]);

  return items.length ? (
    <div data-testid="VideoCarousel" className="video-carousel">
      {slidesPerView ? (
        <Carousel
          slidesPerView={slidesPerView}
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          isDesktop={Boolean(isDesktop)}
          dir={dir}
          isShowPagination={false}
          isShowNavigation={items.length > slidesPerView}
          loop={false}
        >
          {items.map((item) => (
            <div key={item.timeLabel} className="video-carousel__item">
              <button
                aria-label={item.title}
                type="button"
                onClick={onClickHandler(item.timeStart)}
                className="video-carousel__item-btn"
              >
                <div className="video-carousel__item-image">
                  <GatsbyImage image={item.image} />
                </div>

                <div className="video-carousel__item-info">
                  <div className="video-carousel__item-time">{item.timeLabel}</div>
                  <Title className="video-carousel__item-title" Tag="h3">
                    {item.title}
                  </Title>
                </div>
              </button>
            </div>
          ))}
        </Carousel>
      ) : null}
    </div>
  ) : null;
};

export default VideoCarousel;
