import { RefObject } from 'react';

import { isBrowser } from 'utils/browser';
import { HTML_ELEMENTS } from 'utils/constants';

export const scrollIntoViewAccordion = ({
  index,
  tabsHeaderRef,
  parentName,
  openName,
  isMobile,
}: {
  index: number;
  tabsHeaderRef: RefObject<HTMLDivElement>[] | [];
  parentName: string;
  openName: string;
  isMobile: boolean | null;
}) => {
  const tabHeaderEl = tabsHeaderRef?.[index]?.current;
  const headerEl: HTMLElement | null = isBrowser()
    ? document.getElementById(HTML_ELEMENTS.HEADER_ID)
    : null;
  const headerHeight: number = headerEl ? headerEl.clientHeight : 0;
  const viewPortHeight = window.innerHeight - headerHeight;
  const viewPortCenter = viewPortHeight / 2;
  const isOnTop = Number(tabHeaderEl?.getBoundingClientRect()?.y) < viewPortCenter;

  let prevOpenHeight = 0;
  const accordionParent = tabHeaderEl?.closest(`.${parentName}`);
  if (accordionParent) {
    Array.from(accordionParent.children).forEach((item) => {
      if (item.classList.contains(openName)) {
        prevOpenHeight = item.clientHeight;
      }
    });
  }

  if (prevOpenHeight && !isOnTop) {
    window.scrollTo({
      top: window.pageYOffset - prevOpenHeight - headerHeight + viewPortCenter,
      behavior: isMobile ? 'auto' : 'smooth',
    });
  } else {
    tabHeaderEl?.scrollIntoView({
      behavior: isMobile ? 'auto' : 'smooth',
      block: 'center',
    });
  }
};
