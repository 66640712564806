import React, { FC } from 'react';

import ExternalLink from 'components/elements/ExternalLink';
import InfoCard from 'components/elements/InfoCard';
import UpdatedInfo from 'components/elements/UpdatedInfo';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';

import { IPropsContactUs } from './models';

import './ContactUs.scss';

const ContactUs: FC<IPropsContactUs> = ({ contactUs }) =>
  contactUs?.[0] ? (
    <InfoCard data-testid="ContactUs" title={contactUs[0].title} className="contact-us__wrapper">
      <div className="contact-us">
        <div className="contact-us__phone">
          <h3 className="contact-us__block-title">{contactUs[0].callBlock[0].title}</h3>
          {contactUs[0].callBlock[0].items.map((item) => (
            <div key={item.phone}>
              <a
                className="contact-us__phone-number"
                href={`tel:${item.phone}`}
                aria-label={item.ariaLabel}
              >
                {item.phone}
              </a>
              {item?.description ? (
                <DangerouslySetInnerHtml
                  html={item.description}
                  className="contact-us__phone-description"
                />
              ) : null}
            </div>
          ))}
        </div>
        <div className="contact-us__write">
          <h3 className="contact-us__block-title">{contactUs[0].writeBlockTitle}</h3>
          <DangerouslySetInnerHtml
            className="contact-us__write-description"
            html={contactUs[0].writeBlockDescription}
          />
        </div>
        <div className="contact-us__email">
          <h3 className="contact-us__block-title">{contactUs[0].emailBlock[0].title}</h3>
          {contactUs[0].emailBlock[0].items.map((item) => (
            <div className="contact-us__email-wrapper" key={item.title}>
              <span className="contact-us__email-title">{item.title}</span>
              <a
                className="contact-us__email-link"
                href={`mailto:${item.email}`}
                aria-label={item.ariaLabel}
              >
                {item.email}
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="contact-us__bottom">
        {contactUs[0]?.externalLinks?.length ? (
          <div className="contact-us__external-links">
            {contactUs[0]?.externalLinks.map((item) => (
              <ExternalLink key={item.url} link={item.url} title={item.name} target="__blanc" />
            ))}
          </div>
        ) : null}

        {contactUs[0]?.updatedInfo?.length ? (
          <UpdatedInfo updatedInfo={contactUs[0].updatedInfo} />
        ) : null}
      </div>
    </InfoCard>
  ) : null;

export default ContactUs;
